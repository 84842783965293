import React from 'react'
import SEO from '../components/seo';
import Header from '../components/header'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Container from '../components/Container'

const NotFoundPage = () => (
  <Layout>
    <Header />
    <SEO title='Page Not Found' /> 
    <Hero title="404!" subtitle="The page you requested cannot be found." />
    <Container as="main" id='main-content'>
        Content
    </Container>
</Layout>
)

export default NotFoundPage
